import React from "react";
import ProfileHeader from "./ProfileHeader";
import KeyRecord from "./KeyRecord";
import WalletRecord from "./WalletRecord";
import "./Userinfo.css";
import MexcMarketV2 from "./MexcMarketV2";

const UserInfo = () => {
  return (
    <div>
      <ProfileHeader
        title={"Hesap Bilgileri"}
        subtitle={"Kullanıcı bilgileri"}
      />
      <div className="user-info-container">
        <KeyRecord exchange={"Gate"} />
        <WalletRecord exchange={"Gate-Publick"} />
        <WalletRecord exchange={"Gate-Private"} />
        <WalletRecord exchange={"Gate-Deposit-Private"} />
        <KeyRecord exchange={"Mexc"} />
        <MexcMarketV2 exchange={"Mexc-APIV2"} />
        <WalletRecord exchange={"Mexc-Private"} />
        <WalletRecord exchange={"Mexc-Deposit-Private"} />
        <KeyRecord exchange={"Jupiter"} />
      </div>
    </div>
  );
};

export default UserInfo;
