import React, { useState, useEffect } from "react";
import { useApp } from "../context/AppContext";

const JupiterPriceSettingPopup = () => {
  const { setJupiterSend } = useApp();

  // Max Amount ve Max Pct için state'ler
  const [maxAmount, setMaxAmount] = useState(
    () => localStorage.getItem("maxAmount") || 1500
  );
  const [maxPct, setMaxPct] = useState(
    () => localStorage.getItem("maxPct") || 2.5
  );

  // RPC seçenekleri ve ilgili state'ler
  const defaultRpc = "https://grateful-jerrie-fast-mainnet.helius-rpc.com/";
  const secondRpc =
    "https://basic.swqos.solanavibestation.com/?api_key=8efacb2ff4eb2c389174f697059a68c1";
  const [selectedRpcOption, setSelectedRpcOption] = useState("default");
  const [customRpc, setCustomRpc] = useState("");

  useEffect(() => {
    localStorage.setItem("maxAmount", maxAmount);
  }, [maxAmount]);

  useEffect(() => {
    localStorage.setItem("maxPct", maxPct);
  }, [maxPct]);

  useEffect(() => {
    const savedRpc = localStorage.getItem("rpcEndpoint");
    if (savedRpc) {
      if (savedRpc === defaultRpc) setSelectedRpcOption("default");
      else if (savedRpc === secondRpc) setSelectedRpcOption("second");
      else {
        setSelectedRpcOption("custom");
        setCustomRpc(savedRpc);
      }
    }
  }, []);

  const handleMaxAmountChange = (event) => {
    setMaxAmount(event.target.value);
  };

  const handleMaxPctChange = (event) => {
    setMaxPct(event.target.value);
  };

  const handleRpcOptionChange = (event) => {
    setSelectedRpcOption(event.target.value);
  };

  const handleCustomRpcChange = (event) => {
    setCustomRpc(event.target.value);
  };

  const handleApplyRPC = () => {
    // Seçilen RPC'yi belirle ve kaydet
    let rpcEndpoint;
    if (selectedRpcOption === "default") rpcEndpoint = defaultRpc;
    else if (selectedRpcOption === "second") rpcEndpoint = secondRpc;
    else if (selectedRpcOption === "custom") rpcEndpoint = customRpc;
    localStorage.setItem("rpcEndpoint", rpcEndpoint);
    alert("RPC ile ilgili değerler kaydedildi!");
  };

  const handleApplyWebSocket = () => {
    // WebSocket ile ilgili ayarları güncelle
    setJupiterSend({
      method: "updateParams",
      params: {
        bestPrice: true,
        maxAmount: maxAmount,
        maxPct: maxPct,
      },
    });
    alert("WebSocket ile ilgili değerler kaydedildi!");
  };

  return (
    <div style={popupStyle}>
      <h2>Ayarları Düzenle</h2>

      {/* MaxAmount ve MaxPct Girdileri */}
      <div style={inputGroupStyle}>
        <label>Max Amount:</label>
        <input
          type="number"
          value={maxAmount}
          onChange={handleMaxAmountChange}
          style={inputStyle}
        />
      </div>
      <div style={inputGroupStyle}>
        <label>Max Pct:</label>
        <input
          type="number"
          value={maxPct}
          onChange={handleMaxPctChange}
          style={inputStyle}
        />
      </div>
      <button onClick={handleApplyWebSocket} style={buttonStyle}>
        Uygula
      </button>
      {/* RPC Ayarları Bölümü */}
      <h3>RPC Endpoint Seçimi</h3>
      <div style={inputGroupStyle}>
        <label>
          <input
            type="radio"
            value="default"
            checked={selectedRpcOption === "default"}
            onChange={handleRpcOptionChange}
          />
          {defaultRpc}
        </label>
      </div>
      <div style={inputGroupStyle}>
        <label>
          <input
            type="radio"
            value="second"
            checked={selectedRpcOption === "second"}
            onChange={handleRpcOptionChange}
          />
          {secondRpc}
        </label>
      </div>
      <div style={inputGroupStyle}>
        <label>
          <input
            type="radio"
            value="custom"
            checked={selectedRpcOption === "custom"}
            onChange={handleRpcOptionChange}
          />
          Custom:
        </label>
        {selectedRpcOption === "custom" && (
          <input
            type="text"
            value={customRpc}
            onChange={handleCustomRpcChange}
            style={{ ...inputStyle, marginTop: "5px" }}
          />
        )}
      </div>
      <button onClick={handleApplyRPC} style={buttonStyle}>
        RPC Ayarlarını Uygula
      </button>
    </div>
  );
};

const popupStyle = {
  backgroundColor: "white",
  border: "1px solid #ccc",
  borderRadius: "5px",
  padding: "20px",
  width: "300px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
};

const inputGroupStyle = {
  marginBottom: "10px",
};

const inputStyle = {
  width: "100%",
  padding: "8px",
  boxSizing: "border-box",
  marginTop: "5px",
};

const buttonStyle = {
  backgroundColor: "#007BFF",
  color: "white",
  border: "none",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  marginTop: "10px",
};

export default JupiterPriceSettingPopup;
