/* global BigInt */

import {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useMemo,
} from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Walletgeticon, Walletsellicon } from "../../assets/icons/icon";
import "./TradingTrade.css";
import { useApp } from "../../context/AppContext";
import {
  Connection,
  ComputeBudgetProgram,
  PublicKey,
  Transaction,

} from "@solana/web3.js";
import {
  getAssociatedTokenAddress,
  createTransferInstruction,
  createAssociatedTokenAccountInstruction,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

const DEFAULT_RPC_URL = "https://grateful-jerrie-fast-mainnet.helius-rpc.com";

// RPC URL'sini localStorage'den al veya varsayılan URL'yi kullan
const rpcEndpoint = localStorage.getItem("rpcEndpoint") || DEFAULT_RPC_URL;

// Solana bağlantısını oluşturun
const connection = new Connection(rpcEndpoint); // Gerekirse 'devnet' değiştirin

// USDT mint adresi (Solana üzerindeki resmi USDT token adresi)
const USDT_MINT = new PublicKey(
  "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"
);

/** 
 * Basitçe Phantom veya Solflare var mı diye kontrol edebilirsiniz.
 * Jupiter cüzdanı bağladığında window.solana.isConnected = true vb. set edilmiş olur.
 */
function getSolanaProvider() {
  if (window.solana?.isPhantom && window.solana.isConnected) {
    return window.solana;
  } else if (window.solflare?.isConnected) {
    return window.solflare;
  }
  return null;
}

function showNotification(message, type) {
  const notification = document.createElement("div");
  notification.innerText = message;
  notification.style.position = "fixed";
  notification.style.bottom = "20px";
  notification.style.right = "20px";
  notification.style.backgroundColor =
    type === "success" ? "green" :
    type === "error" ? "red" : "gray";
  notification.style.color = "white";
  notification.style.padding = "10px";
  notification.style.borderRadius = "5px";
  notification.style.zIndex = 1000;
  notification.style.boxShadow = "0px 0px 10px rgba(0,0,0,0.3)";
  document.body.appendChild(notification);
  setTimeout(() => {
    document.body.removeChild(notification);
  }, 2000);
}

/**
 * 1) Alıcının token hesabı yoksa oluşturur.
 * 2) Ardından token transferini gerçekleştirir.
 */
const sendToken = async (
  recipientAddress,
  tokenMintAddress,
  amount,
  desiredFeeLamports,
  provider
) => {
  if (!provider || !provider.publicKey) {
    throw new Error("Cüzdan bağlı değil veya geçersiz.");
  }

  const mintPublicKey = new PublicKey(tokenMintAddress);
  const recipientPublicKey = new PublicKey(recipientAddress);

  // Gönderen cüzdan
  const sender = provider;

  // Gönderenin token hesabı
  const senderTokenAddress = await getAssociatedTokenAddress(
    mintPublicKey,
    sender.publicKey
  );

  // Alıcının token hesabı
  const recipientTokenAddress = await getAssociatedTokenAddress(
    mintPublicKey,
    recipientPublicKey
  );

  let accountInfo = await connection.getAccountInfo(recipientTokenAddress);
  if (!accountInfo) {
    // Hedef token hesabı yoksa oluştur
    showNotification(
      "Hedef adreste token hesabı yok. Şimdi oluşturulacak. Lütfen cüzdanınızda onaylayın.",
      "info"
    );

    const createAccountTx = new Transaction().add(
      createAssociatedTokenAccountInstruction(
        sender.publicKey,
        recipientTokenAddress,
        recipientPublicKey,
        mintPublicKey
      )
    );

    createAccountTx.feePayer = sender.publicKey;
    const { blockhash } = await connection.getLatestBlockhash();
    createAccountTx.recentBlockhash = blockhash;

    try {
      const signedTx = await sender.signTransaction(createAccountTx);
      const signature = await connection.sendRawTransaction(signedTx.serialize());
      showNotification("Token hesabı oluşturma işlemi gönderildi...", "info");

      await connection.confirmTransaction(signature);
      showNotification("Hedef token hesabı başarıyla oluşturuldu!", "success");
    } catch (error) {
      console.error("Token hesabı oluşturulurken hata:", error);
      showNotification("Token hesabı oluşturulurken hata: " + error.message, "error");
      throw error;
    }
  }

  // Asıl token transferi
  showNotification("Token transferi başlıyor. Lütfen onaylayın...", "info");
  const transferTx = new Transaction().add(
    createTransferInstruction(
      senderTokenAddress,
      recipientTokenAddress,
      sender.publicKey,
      amount,
      [],
      TOKEN_PROGRAM_ID
    )
  );

  if (desiredFeeLamports && desiredFeeLamports > 0) {
    transferTx.add(
      ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: desiredFeeLamports,
      })
    );
  }

  transferTx.feePayer = sender.publicKey;
  const { blockhash } = await connection.getLatestBlockhash();
  transferTx.recentBlockhash = blockhash;

  try {
    const signedTx = await sender.signTransaction(transferTx);
    const signature = await connection.sendRawTransaction(signedTx.serialize());
    console.log("Transfer işlemi gönderildi, imza:", signature);

    await connection.confirmTransaction(signature);
    showNotification("Token transferi başarılı!", "success");
  } catch (error) {
    if (error.message.includes("User rejected the request")) {
      showNotification("İşlem reddedildi.", "error");
    } else {
      console.error("Transfer hatası:", error);
      showNotification("Token transferi hatası: " + error.message, "error");
    }
    throw error;
  }
};

const TradingTrade = forwardRef(({ tradeMode, mobile }, ref) => {
  const { exchange, currency } = useParams();
  const location = useLocation();
  const { balance, service, apiKey, apiSecret } = useApp();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const tradeToken = useMemo(() => ({
    buyPrice: queryParams.get("buyPrice"),
    sellPrice: queryParams.get("sellPrice"),
    buyVolume: queryParams.get("buyVolume"),
    sellVolume: queryParams.get("sellVolume"),
    amount: queryParams.get("amount"),
    logoURI: queryParams.get("logoURI"),
    contractAddress: queryParams.get("contractAddress"),
    jup: queryParams.get("jup"),
    currencyA: currency.split("_")[0],
    stockA: exchange,
  }), [queryParams, currency, exchange]);

  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [tokenBalance, setTokenBalance] = useState({ available: 0, locked: 0 });

  // Alış veya satış?
  const isBuyMode = tradeMode === "buy";

  useEffect(() => {
    // Belirlenen token cinsinden bakiyeyi bul
    const curr = isBuyMode ? "USDT" : tradeToken.currencyA;
    const result = balance.find((item) => item.currency === curr);
    if (result) {
      setTokenBalance({ available: result.available, locked: result.locked });
    } else {
      setTokenBalance({ available: 0, locked: 0 });
    }
  }, [balance, tradeToken.currencyA, isBuyMode]);

  // Fiyat / Adet / Toplam ayarlama
  useEffect(() => {
    if (isBuyMode) {
      setPrice(tradeToken.sellPrice || 0);
      setAmount(tradeToken.sellVolume || 0);
      let initialTotal = (tradeToken.sellPrice || 0) * (tradeToken.sellVolume || 0);
      if (initialTotal > tokenBalance.available) {
        initialTotal = tokenBalance.available;
        setAmount(initialTotal / (tradeToken.sellPrice || 1));
      }
      setTotal(initialTotal);
    } else {
      setPrice(tradeToken.buyPrice || 0);
      setAmount(tradeToken.buyVolume || 0);
      let initialTotal = (tradeToken.buyPrice || 0) * (tradeToken.buyVolume || 0);
      if (initialTotal > tokenBalance.available) {
        initialTotal = tokenBalance.available;
        setAmount(initialTotal / (tradeToken.buyPrice || 1));
      }
      setTotal(initialTotal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeToken, isBuyMode]);

  useImperativeHandle(ref, () => ({
    setPrice,
    setAmount,
    setTotal,
    tokenBalance,
  }));

  const handlePriceChange = (e) => {
    const newPrice = parseFloat(e.target.value) || 0;
    setPrice(newPrice);
    if (amount) {
      let newTotal = newPrice * amount;
      if (newTotal > tokenBalance.available) {
        newTotal = tokenBalance.available;
        setTotal(newTotal);
        setAmount(newTotal / newPrice);
      } else {
        setTotal(newTotal);
      }
    }
  };

  const handleAmountChange = (e) => {
    let newAmount = parseFloat(e.target.value) || 0;
    let newTotal = price * newAmount;
    if (newTotal > tokenBalance.available) {
      newTotal = tokenBalance.available;
      newAmount = newTotal / (price || 1);
      setAmount(newAmount);
      setTotal(newTotal);
    } else {
      setAmount(newAmount);
      setTotal(newTotal);
    }
  };

  const handleTotalChange = (e) => {
    let newTotal = parseFloat(e.target.value) || 0;
    if (newTotal > tokenBalance.available) {
      newTotal = tokenBalance.available;
    }
    setTotal(newTotal);
    if (price) {
      setAmount(newTotal / price);
    }
  };

  // % butonları
  const handleClick = (percentage) => {
    let newTotal;
    if (isBuyMode) {
      newTotal = (percentage / 100) * tokenBalance.available;
      if (newTotal > tokenBalance.available) {
        newTotal = tokenBalance.available;
      }
      const newAmount = newTotal / (price || 1);
      setAmount(newAmount);
      setTotal(newTotal);
    } else {
      let newAmount = (percentage / 100) * tokenBalance.available;
      if (price) {
        newTotal = newAmount * price;
        if (newTotal > tokenBalance.available) {
          newTotal = tokenBalance.available;
          newAmount = newTotal / price;
          setAmount(newAmount);
          setTotal(newTotal);
        } else {
          setAmount(newAmount);
          setTotal(newTotal);
        }
      } else {
        setAmount(newAmount);
      }
    }
  };

  // Alış / Satış emri oluşturma
  const createOrder = async (e) => {
    e.preventDefault();
    if (total < 6) {
      alert("Minimum işlem tutarı 6 USD olmalıdır.");
      return;
    }
    if (total > tokenBalance.available) {
      alert("Bakiyenizden fazla tutarda işlem yapamazsınız.");
      setTotal(tokenBalance.available);
      setAmount(tokenBalance.available / price);
      return;
    }
    if (!isBuyMode && amount > tokenBalance.available) {
      alert("Bakiyenizden fazla miktarda işlem yapamazsınız.");
      setAmount(tokenBalance.available);
      setTotal(tokenBalance.available * price);
      return;
    }

    try {
      const order = await service.Spot.createOrder(
        "limit",
        `${tradeToken.currencyA}_USDT`,
        isBuyMode ? "buy" : "sell",
        amount,
        total,
        price,
        { key: apiKey, secret: apiSecret }
      );
      console.log("Order created:", order);
    } catch (error) {
      console.error("Order creation error:", error);
    }
  };

  // Borsadan Sol cüzdana hızlı çekim
  const handleFastWithdraw = async () => {
    const currency = isBuyMode ? "USDT" : tradeToken.currencyA;
    const amount = tokenBalance.available;
    const recipientAddress = localStorage.getItem(`${exchange}-Private-Wallet`);
    const chain = exchange === "Mexc" ? "Solana(SOL)" : "SOL";

    if (!recipientAddress) {
      alert("Lütfen öncelikle çekim adresinizi ayarlayın.");
      return;
    }

    if (amount <= 0) {
      alert("Çekilecek bir bakiye bulunmamaktadır.");
      return;
    }

    const params = {
      currency: currency,
      address: recipientAddress,
      amount: amount,
      memo: "",
      chain: chain,
    };

    const headers = {
      key: apiKey,
      secret: apiSecret,
    };

    try {
      console.log("Çekim parametreleri:", params, headers);
      await service.Wallet.withdraw(params, headers);
      alert("Çekim işlemi başlatıldı.");
    } catch (error) {
      console.error("Çekim işlemi sırasında hata oluştu:", error);
      alert(`Hata oluştu: ${JSON.stringify(error)}`);
    }
  };

  /**
   * Sol cüzdandan (Phantom ya da Solflare) borsaya USDT/SPL token transferi
   * Artık Jupiter'e güveniyoruz, cüzdan eğer bağlandıysa window.solana.isConnected vb. true olur.
   * BAKİYE KONTROLÜNÜ 10 SANİYE (HER 1 SANİYEDE 1 KEZ) YAPIP BAKİYEYİ ARIYOR.
   */
  const handleSendUSDT = async () => {
    // Jupiter cüzdanı bağlamış mı kontrol et
    const provider = getSolanaProvider();
    if (!provider) {
      showNotification(
        "Cüzdan bağlı değil veya izin verilmedi. Lütfen Jupiter arayüzünden cüzdanınızı bağlayın.",
        "error"
      );
      return;
    }

    const tokenContractAddress = isBuyMode
      ? USDT_MINT.toString()
      : tradeToken.contractAddress;
    const recipientAddress = localStorage.getItem(
      `${exchange}-Deposit-Private-Wallet`
    );

    if (!recipientAddress) {
      showNotification("Lütfen cüzdan adresinizi ayarlayın.", "error");
      return;
    }

    // Geçerli Solana adresi mi?
    try {
      new PublicKey(recipientAddress);
    } catch (error) {
      showNotification("Geçersiz cüzdan adresi.", "error");
      return;
    }

    // 10 saniye boyunca (1 saniye aralıklarla) bakiye kontrolü yapacağız:
    showNotification("Cüzdan bakiyesi aranıyor... (max 10sn)", "info");

    let foundBalance = false;
    let availableAmount = 0n;

    for (let i = 0; i < 10; i++) {
      // 1) Gönderenin token hesabı var mı
      let tokenAccounts;
      try {
        tokenAccounts = await connection.getTokenAccountsByOwner(
          provider.publicKey,
          { mint: new PublicKey(tokenContractAddress) }
        );
      } catch (error) {
        showNotification("Token hesapları alınamadı.", "error");
        return;
      }

      if (tokenAccounts.value.length > 0) {
        // 2) Bakiyeyi alalım
        const senderTokenAccount = tokenAccounts.value[0].pubkey;
        let tokenAccountInfo;
        try {
          tokenAccountInfo = await connection.getTokenAccountBalance(senderTokenAccount);
        } catch (error) {
          showNotification("Token bakiyesi alınamadı.", "error");
          return;
        }

        availableAmount = BigInt(tokenAccountInfo.value.amount);
        if (availableAmount > 0n) {
          foundBalance = true;
          break; // Bakiye bulundu, döngüden çık
        }
      }

      // Bakiye yok, 1 saniye bekle
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    // 10 saniye sonunda hâlâ bakiye yoksa işlemi iptal edelim
    if (!foundBalance) {
      showNotification("Bakiye bulunamadı, işlem iptal.", "error");
      return;
    }

    // Bakiye bulundu, token gönderimine geçebiliriz
    try {
      showNotification("İşlem başlatılıyor...", "info");
      await sendToken(
        recipientAddress,
        tokenContractAddress,
        availableAmount,
        0,
        provider
      );
     
    } catch (error) {
      showNotification("İşlem başarısız.", "error");
    }
  };

  return (
    <div className={`trading__trade ${mobile === undefined ? "" : mobile}`}>
      <div className="trading__main">
        <form
          className={`trading__form ${isBuyMode ? "buy" : "sell"}`}
          onSubmit={createOrder}
        >
          <label>
            <span>Fiyat</span>
            <input
              type="number"
              placeholder="0.00"
              value={price}
              onChange={handlePriceChange}
            />
          </label>
          <label>
            <span>Adet</span>
            <input
              type="number"
              placeholder="0.00"
              value={amount}
              onChange={handleAmountChange}
            />
          </label>
          <label>
            <span>Toplam</span>
            <input
              type="number"
              placeholder="0.00"
              value={total}
              onChange={handleTotalChange}
            />
          </label>
          <button
            type="submit"
            className={`trading__form-${isBuyMode ? "buy" : "sell"}`}
          >
            {isBuyMode ? "Alış Emri Ver" : "Satış Emri Ver"}
          </button>
        </form>
        <div className="sidebar">
          {[25, 50, 75, 100].map((percentage) => (
            <button
              key={percentage}
              onClick={() => handleClick(percentage)}
              className="sidebar__button"
            >
              {percentage}%
            </button>
          ))}
        </div>
      </div>
      <div className="trading__balance d-flex-desktop">
        <div>
          <div className="balance__total">
            <span>
              <span className="w-50 d-inline-block">Mevcut</span>{" "}
              {isBuyMode ? "USDT" : tradeToken.currencyA} :{" "}
              {parseFloat(tokenBalance.available).toFixed(2)}
            </span>
          </div>
          <div className="balance__available">
            <span>
              <span className="w-50 d-inline-block">Kilitli</span>{" "}
              {isBuyMode ? "USDT" : tradeToken.currencyA} :{" "}
              {parseFloat(tokenBalance.locked).toFixed(2)}
            </span>
          </div>
        </div>
        <div className="balance__transfer" style={{ position: "relative" }}>
          {/* Hızlı deposit: Sol cüzdandan borsaya token gönder */}
          <button className="fast-deposit-btn" onClick={handleSendUSDT}>
            {Walletgeticon}
          </button>

          {/* Normal deposit linki */}
          <Link to={`/deposit/${isBuyMode ? "USDT" : tradeToken.currencyA}`}>
            <button className="balance__get al-icon-btn">
              {Walletgeticon}
            </button>
          </Link>
          {/* Normal withdraw linki */}
          <Link to={`/withdraw/${isBuyMode ? "USDT" : tradeToken.currencyA}`}>
            <button className="balance__sell sat-icon-btn">
              {Walletsellicon}
            </button>
          </Link>

          {/* Hızlı withdraw: Borsadan Sol cüzdana çekim */}
          <button className="fast-withdraw-btn" onClick={handleFastWithdraw}>
            {Walletsellicon}
          </button>
        </div>
      </div>
      {/* 
        Connect Wallet butonu tamamen kaldırıldı.
        İsterseniz burada "Cüzdan Bağlı mı?" durumu
        gösteren basit bir text ekleyebilirsiniz.
      */}
    </div>
  );
});

export default TradingTrade;
